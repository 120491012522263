import React, { useEffect } from 'react';
 
const FacebookPagePlugin = () => {
  useEffect(() => {
    // Load Facebook SDK
    if (window.FB) {
      window.FB.XFBML.parse();
    } else {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: 'your-app-id', // Replace with your Facebook App ID
          xfbml: true,
          version: 'v12.0',
        });
        window.FB.XFBML.parse();
      };

      // Load the Facebook SDK script
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);

  return (
    <div>
      {/* Facebook Page Plugin */}
      <div
        className="fb-page"
        data-href="https://www.facebook.com/htsjsr/"
        data-tabs="timeline"
        data-width="1000"
        data-height="350"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      ></div>
    </div>
  );
};
  /* const FacebookPagePlugin = () => {
    useEffect(() => {
        // Create a script element to load the Facebook SDK
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0';
        script.nonce = 'k5774A6o';
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="fb-root">
            <div 
                className="fb-page"
                data-href="https://www.facebook.com/htsjsr/"
                data-height="500"
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="false"
                data-show-posts="true"
                data-width="1000"
            >
                <blockquote
                    cite="https://www.facebook.com/htsjsr/"
                    className="fb-xfbml-parse-ignore"
                >
                    <a href="https://www.facebook.com/htsjsr/">Hill Top School</a>
                </blockquote>
            </div>
        </div>
    );
}; */

export default FacebookPagePlugin;

