import React  from 'react'; 
import { Link } from 'react-router-dom';
const Fees = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Policies</li>
                 <li>Fees</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Fees</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                      <h3>Hill Top School handles all applications as per the admissions policy and applicable rules.</h3>
                     <ul className="list newlist">
                     <li>The school fees cover twelve months. Pupils will be charged full fees as long as their names are on the rolls. </li>
                     <li>Fees in Online mode must be paid between the 5th and 20th of every month. </li>
                     <li>Fees for January, February & March must be paid in January by all students. </li>
                     <li>Fees for the whole year may be paid in advance, if desired. </li>
                     <li>Late fees will be received with a fine of Rs. 25/- in the next month and Rs. 50/- in the second Month. (The name of the defaulter for consecutive two months will be struck off the rolls in the third month & on the third month and onwards Rs. 500/- will be collected as re-admission charge with normal fees). </li>
                     </ul>
 </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Fees

