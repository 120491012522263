import React  from 'react'; 
import { Link } from 'react-router-dom';
const HillTopFamily = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  About Us</li>
                 <li>Hill Top Family</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Hill Top Family</h1>
                    </div>
                </div>
                <div className="row teachertable"> 
                <div className="col-md-12"> 
                <table class="table" cellspacing="0" border="0">
                <tbody>
                   <tr>
                         <td colspan="2" class="tabletr">  Pricipal </td>
                     </tr>
                     <tr>
                     <td colspan="2" className="text-center"><b>Mrs. Uma Tiwary</b></td>
                     </tr>
                     <tr>
                         <td colspan="2" class="tabletr"> Vice Pricipal </td>
                     </tr>
                     <tr>
                     <td colspan="2" className="text-center"><b> -</b></td>
                     </tr>
                </tbody>
                </table></div>
                <div className="col-md-6"> 
               <table class="table" cellspacing="0" border="0">
               <tbody> 
                                    <tr>
               <td colspan="2" class="tabletr"> Senior School Segment</td>
               </tr>  
                 <tr>
                       <td>1</td>
                       <td><b>Mr. Ram Ratan Dubey (Senior School Coordinator &amp; HOD Physics)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td><b>Mr. Sadhan Kumar Patra (HOD Mathematics)</b></td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td><b>Mrs. Shalini Nair (HOD English)</b></td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td><b>Mrs. Geetha Sunil (HOD Computer Science)</b></td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td><b>Mr. Kishore Kumar Sinha (HOD Chemistry)</b></td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td><b>Mrs. MVT Brahmarambha (HOD Biology)</b></td>
                   </tr>
                   <tr>
                       <td>7</td>
                       <td>Mr. Avinash Kumar Tripathy</td>
                   </tr>
                   <tr>
                       <td>8</td>
                       <td>Mrs. Mala Bhattacharjee</td>
                   </tr>
                   <tr>
                       <td>9</td>
                       <td>Mrs. Vimala Nagavelu</td>
                   </tr>
                   <tr>
                       <td>10</td>
                       <td>Ms. Tanuja</td>
                   </tr>
                   <tr>
                       <td>11</td>
                       <td>Mr. Md. Soaib Khan</td>
                   </tr>
                   <tr>
                       <td>12</td>
                       <td>Mrs. Poonam Sharma</td>
                   </tr>
                   <tr>
                       <td>13</td>
                       <td>Ms. Simran Kaur</td>
                   </tr>
                   <tr>
                       <td>14</td>
                       <td>Mr. Vikash Kumar Das</td>
                   </tr>
                   <tr>
                       <td>15</td>
                       <td>Mrs. Soumya Chakraborty</td>
                   </tr> 
                  <tr><td colspan="2" class="tabletr"> Secondary School Segment </td>
               </tr> 
                 <tr>
                       <td>1</td>
                       <td><b>Mrs. Jaswinder Kaur (Secondary School Coordinator &amp; HOD Hindi)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mrs. Manashi Majumdar</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td>Mrs. Mukta Verma</td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td>Mr. Rizwan Khan</td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td>Mrs. Arwinder Kaur</td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td>Mrs. Artee Singh</td>
                   </tr>
                   <tr>
                       <td>7</td>
                       <td>Mrs. Harpreet Kour</td>
                   </tr>
                   <tr>
                       <td>8</td>
                       <td>Mr. Radhe Shyam Singh</td>
                   </tr>
                   <tr>
                       <td>9</td>
                       <td><b>Mrs. Anamitra Mukherjee (HOD Humanities)</b></td>
                   </tr>
                   <tr>
                       <td>10</td>
                       <td>Mrs. Neelam Singh</td>
                   </tr>
                   <tr>
                       <td>11</td>
                       <td>Ms. Priyanka Kar</td>
                   </tr>
                   <tr>
                       <td>12</td>
                       <td>Mrs. Reshmi Prabha</td>
                   </tr>
                   <tr>
                       <td>13</td>
                       <td>Ms.Latika Kumari</td>
                   </tr>
                   <tr>
                       <td>14</td>
                       <td>Mrs. Suneetha Pilla</td>
                   </tr>
                     <tr>
               <td colspan="2" class="tabletr"> Middle School Segment</td>
               </tr>
               <tr>
                       <td>1</td>
                       <td><b>Mrs. Sheeja Abraham (Middle School Coordinator)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Ms. Amarjeet Kaur</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td>Mrs. Sheeba Varghese</td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td>Mr. Pinku Seth</td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td>Mrs. Sreeparna Goswami</td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td>Mrs. Sabita Kumari</td>
                   </tr>
                   <tr>
                       <td>7</td>
                       <td>Mr. Rakesh Kumar Singh</td>
                   </tr>
                   <tr>
                       <td>8</td>
                       <td>Mrs. Seema Bhowmick</td>
                   </tr>
                   <tr>
                       <td>9</td>
                       <td>Mrs. Tania Chatterjee</td>
                   </tr>
                   <tr>
                       <td>10</td>
                       <td>Mrs. Sony</td>
                   </tr>
                   <tr>
                       <td>11</td>
                       <td>Mrs. Ritu</td>
                   </tr>
                   <tr>
                       <td>12</td>
                       <td>Mrs. Alice Bazil Massie</td>
                   </tr>
                   <tr>
                       <td>13</td>
                       <td>Mrs. Aditi Pradhan</td>
                   </tr>
                   <tr>
                       <td>14</td>
                       <td>Mrs. Maninder Kaur</td>
                   </tr>
                   <tr>
                       <td>15</td>
                       <td>Mrs. Madhumita Jha</td>
                   </tr>
                   <tr>
                       <td>16</td>
                       <td>Mr. Sanjay Kumar Tripathi</td>
                   </tr>
                   <tr>
                       <td>17</td>
                       <td>Mrs. Tahira Khatoon</td>
                   </tr>
                   <tr>
                       <td>18</td>
                       <td>Ms. Isha Vatsa</td>
                   </tr>
                   <tr>
                       <td>19</td>
                       <td>Mrs. Parveen Kaur Virdi</td>
                   </tr>
                   <tr>
                       <td>20</td>
                       <td>Ms. Shweta Kumari</td>
                   </tr>
                   <tr>
                       <td>21</td>
                       <td>Mr. Amar Mukhi</td>
                   </tr>
                   <tr>
                       <td>22</td>
                       <td>Mrs. Kanta Sarkar</td>
                   </tr>
                   <tr>
                       <td>23</td>
                       <td>Mrs. Suchandra Biswas</td>
                   </tr>
                   <tr>
                       <td>24</td>
                       <td>Mr. Sourav Dutta</td>
                   </tr>
                   <tr>
                       <td>25</td>
                       <td>Mrs. Sonali Majhi</td>
                   </tr>
                   <tr>
                       <td>26</td>
                       <td>Mrs. Ritu Kumari</td>
                   </tr>
                         <tr>
               <td colspan="2" class="tabletr">Office Team</td>
               </tr>
                 <tr>
                       <td>1</td>
                       <td><b>Mr. Victor George (Office Coordinator)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mrs. Shipra Khandai</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td><b>Mr. Pranav Ranjan Chatterjee (IT Facilitator)</b></td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td>Mrs. Manjula Kannan</td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td>Mr. Prashant Khalkho</td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td>Mr. Ranjan Kumar Mukherjee</td>
                   </tr>
                 <tr>
               <td colspan="2" class="tabletr">Librarians</td>
               </tr>
                <tr>
                       <td>1</td>
                       <td>Mrs. Nitu Singh (Senior Librarian)</td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mrs. Shyamashree Chakraborty (Junior Librarian)</td>
                   </tr>
               </tbody> 
               </table>
               </div>
                <div className="col-md-6"> 
               <table class="table" cellspacing="0" border="0">
                <tbody>
                 <tr>
               <td colspan="2" class="tabletr"> Primary School Segment</td>
               </tr>
                  <tr>
                       <td>1</td>
                       <td><b>Mrs. Leeza Karak (Primary Coordinator)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mr. Sushant Kumar Ghoshal</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td>Mrs. A Sita</td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td>Mrs. Shalini Sharan</td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td>Mrs. Abida Khatoon</td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td>Mrs. Mamta Choudhary</td>
                   </tr>
                   <tr>
                       <td>7</td>
                       <td>Mrs. Sylvia Rosy Stephen</td>
                   </tr>
                   <tr>
                       <td>8</td>
                       <td>Mrs. Rashmi Kumari</td>
                   </tr>
                   <tr>
                       <td>9</td>
                       <td>Mrs. Nisha Nilesh Rangooni</td>
                   </tr>
                   <tr>
                       <td>10</td>
                       <td>Mrs. Sangita Rani</td>
                   </tr>
                   <tr>
                       <td>11</td>
                       <td>Mrs. G Kavita</td>
                   </tr>
                   <tr>
                       <td>12</td>
                       <td>Ms. Tushita Chakraborty</td>
                   </tr>
                   <tr>
                       <td>13</td>
                       <td>Mrs. Anita Sharma</td>
                   </tr>
                   <tr>
                       <td>14</td>
                       <td>Mrs. Dilraj Kaur</td>
                   </tr>
                   <tr>
                       <td>15</td>
                       <td>Mrs. Balwinder Kaur</td>
                   </tr>
                   <tr>
                       <td>16</td>
                       <td>Mrs. Mandeep Kaur Dhanjal</td>
                   </tr>
                   <tr>
                       <td>17</td>
                       <td>Mrs. Archana Mehta</td>
                   </tr>
                   <tr>
                       <td>18</td>
                       <td>Mrs. Pinki Kumari</td>
                   </tr>
                   <tr>
                       <td>19</td>
                       <td>Mrs. Queeny Harriet Thomas</td>
                   </tr>
                   <tr>
                       <td>20</td>
                       <td>Mr. Mitendra Nath Gorai</td>
                   </tr>
                   <tr>
                       <td>21</td>
                       <td>Mrs. Lisa Chatterjee</td>
                   </tr>
                   <tr>
                       <td>22</td>
                       <td>Ms. Bina Kumari Mukhi</td>
                   </tr>
                   <tr>
                       <td>23</td>
                       <td>Mrs. Sangeeta Singh </td>
                   </tr>
                   <tr>
                       <td>24</td>
                       <td>Mrs. Vanessa A. Francis</td>
                   </tr>
                   <tr>
                       <td>25</td>
                       <td>Mrs. Payal Dey</td>
                   </tr>
                   <tr>
                       <td>26</td>
                       <td>Mrs. Vani Vandana Tiwary</td>
                   </tr>
                   <tr>
                       <td>27</td>
                       <td>Mrs. Alisha Anthony</td>
                   </tr>
                   <tr>
                       <td>28</td>
                       <td>Mrs. Jasbir Kaur Gurmeet singh Rao</td>
                   </tr>
                   <tr>
                       <td>29</td>
                       <td>Mrs. Ruma Banarjee</td>
                   </tr>
                   <tr>
                       <td>30</td>
                       <td>Mrs. Nisha Kumari</td>
                   </tr>
                   <tr>
                       <td>31</td>
                       <td>Mr. Yellapu Pranay Kumar</td>
                   </tr>
                   <tr>
                       <td>32</td>
                       <td>Mrs. Shilpi Kumari</td>
                   </tr>
                   <tr>
                       <td>33</td>
                       <td>Mrs. Neetu Kashyap</td>
                   </tr>
                   <tr>
                       <td>34</td>
                       <td>Ms. Anaida Anthony</td>
                   </tr> 
                     <tr>
               <td colspan="2" class="tabletr"> Pre Primary School Segment</td>
               </tr>
                 <tr>
                       <td>1</td>
                       <td><b>Mrs. Nameeta (Pre-Primary Coordinator)</b></td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mrs. Tanya Nagi Babra</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td>Mrs. Jaya Ghatak</td>
                   </tr>
                   <tr>
                       <td>4</td>
                       <td>Mrs. Nisha Mahato</td>
                   </tr>
                   <tr>
                       <td>5</td>
                       <td>Mrs. Rajani Kaur</td>
                   </tr>
                   <tr>
                       <td>6</td>
                       <td>Mrs. Neetu Maity</td>
                   </tr>
                   <tr>
                       <td>7</td>
                       <td>Mrs. Shilpi Chakraborty</td>
                   </tr>
                   <tr>
                       <td>8</td>
                       <td>Ms. Sukhwinder Kaur</td>
                   </tr>
                   <tr>
                       <td>9</td>
                       <td>Mrs. Pooja Mahato</td>
                   </tr>
                   <tr>
                       <td>10</td>
                       <td>Ms. Shahina Parween</td>
                   </tr>
                   <tr>
                       <td>11</td>
                       <td>Mrs. Soni Pandey</td>
                   </tr>
                   <tr>
                       <td>12</td>
                       <td>Mrs. Akundi Lalita Devi</td>
                   </tr>
                   <tr>
                       <td>13</td>
                       <td>Mrs. Duli Mardi</td>
                   </tr>
                   <tr>
                       <td>14</td>
                       <td>Mrs. Priyanka Andreya Ghosh</td>
                   </tr>
                   <tr>
                       <td>15</td>
                       <td>Mrs. Pallavi Gummalla</td>
                   </tr>
                   <tr>
                       <td>16</td>
                       <td>Mrs. Ruqaiyah Khanum</td>
                   </tr>
                   <tr>
                       <td>17</td>
                       <td>Ms. Debanjali Chatterjee</td>
                   </tr>
                   <tr>
                       <td>18</td>
                       <td>Ms. Lilian Elizabeth Lake</td>
                   </tr>
                   <tr>
                       <td>19</td>
                       <td>Mrs. Sneha Dutta</td>
                   </tr>
                   <tr>
                       <td>20</td>
                       <td>Mrs. Anjali Kumari</td>
                   </tr>
                   <tr>
                       <td>21</td>
                       <td>Ms. Somali Bose</td>
                   </tr>
                   <tr>
                       <td>22</td>
                       <td>Ms. Yashdeep Kaur</td>
                   </tr>
                   <tr>
                       <td>23</td>
                       <td>Mrs. Chitra Smitha Duddi</td>
                   </tr>
                   <tr>
                       <td>24</td>
                       <td>Ms. Nidhi Parmar</td>
                   </tr>
                   <tr>
                       <td>25</td>
                       <td>Ms. Tresa Victor Abraham</td>
                   </tr>
                   <tr>
                       <td>26</td>
                       <td>Ms. Alisha Fabian Peters</td>
                   </tr>
                   <tr>
                       <td>27</td>
                       <td>Mrs. Rimpa Chatterjee</td>
                   </tr>
                   <tr>
                       <td>28</td>
                       <td>Mrs. Tanaya Sengupta</td>
                   </tr>
                     <tr>
               <td colspan="2" class="tabletr"> Lab Assistants</td>
               </tr>
                <tr>
                       <td>1</td>
                       <td>Mr. Venkatesh Murthy</td>
                   </tr>
                   <tr>
                       <td>2</td>
                       <td>Mr. Ajit Kumar</td>
                   </tr>
                   <tr>
                       <td>3</td>
                       <td>Mr. Rakesh Kumar Sharma</td>
                   </tr>
               </tbody> 
               </table>
               </div>
               </div>
            </div>
        </div>
     </>
  )
}

export default HillTopFamily

