import React  from 'react'; 
import { Link } from 'react-router-dom';
const Exam = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Students</li>
                 <li>Exam</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Exam</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <h3>For most classes, a specific exam pattern is followed. Weightage is given to all assessments taken round the year.</h3>
                       
                       <ul className="list newlist">
                       <li> <b>Nursery</b> Informal assessments taken round the year. </li>
                       <li> <b>LKG, UKG</b>  Four rounds of assessments taken. </li>
                       <li> <b>I to IV</b> Two semesters of 100 marks weightage in each subject, Each semester has a Weekly Test cycle of 30 marks, Internal Assessment of 20 marks and a major exam (Half Yearly of Final) of 50 marks. </li>
                       <li> <b>V-IX </b> Two semesters in a year, 150 marks weightage in each subject, per semester. Weekly Test Cycles of 50 marks. Internal Assessments of 20 marks. Major Exams (Half Yearly/Finals) of 80 marks. </li>
                       <li> <b>X </b> First semester as above. Second Semester comprises Pre ICSE Exams. </li>
                       <li><b> XI-XII </b> Two semesters in a year of 200 marks weightage per subject. Weekly Test and Internal Assessment totaling 100 marks. Major Exams (Half Yearly/Finals) of 100 marks. </li>
                       </ul>
                         
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Exam

