import React  from 'react'; 
import { Link } from 'react-router-dom';
const StudentCouncil = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Students</li>
                 <li>Student Council</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Student Council</h1>
                    </div>
                </div>
                <div className="row studentcouncil">
                <div className="col-md-12">
                <h3>Senior Cabinet</h3>
              <table class="table" cellspacing="0" border="0">
              <tbody><tr>
                  <th scope="col">Designantion</th><th scope="col">Name</th>
              </tr>
              <tr> <td>President (Boy) </td> <td><b>Ram Rachit Dubey</b> </td> </tr> 
             <tr> <td>President (Girl) </td> <td><b>Tanvi Pankaj </b></td> </tr> 
             <tr> <td>Vice President (Boy) </td> <td><b>Mehul Kumar </b></td> </tr> 
             <tr> <td>Vice President (Girl) </td> <td><b>Meenal Murmu </b></td> </tr> 
             <tr> <td>Class 12 Councillor (Boy) </td> <td><b>Krish Kumar </b></td> </tr> 
             <tr> <td>Class 12 Councillor (Girl) </td> <td><b>Kavya Kumari </b></td> </tr> 
             <tr> <td>Special Incharge of Discipline </td> <td> <b>Ayush Ranjan & Astha Tiwary</b> </td> </tr>
          </tbody></table>
</div>
 <div className="col-md-6">
 <h3>Akbar House</h3>
<table class="table" cellspacing="0" border="0">
<tbody><tr>
<th scope="col">Designantion</th><th scope="col">Name</th>
</tr>  
<tr> <td> Captain (Boy) </td> <td> <b>Akshat Singh</b></td> </tr>
<tr> <td> Captain (Girl) </td> <td><b> Afreen Hussain</b></td> </tr>
<tr> <td> Vice Captain (Boy) </td> <td> <b>Aditya Kumar</b></td> </tr>
<tr> <td> Vice Captain (Girl) </td> <td><b> Naira Afsheen</b></td> </tr>
<tr> <td> Coordinator </td> <td><b> Shruti Kumari</b></td> </tr>
</tbody></table>
</div>
<div className="col-md-6">
 <h3>Ashoka House</h3>
<table class="table" cellspacing="0" border="0">
<tbody><tr>
<th scope="col">Designantion</th><th scope="col">Name</th>
</tr>
<tr> <td> Captain (Boy) </td> <td> <b>Kaushik Fouzdar</b></td> </tr>
<tr> <td> Captain (Girl) </td> <td><b> Ankita Sharma</b></td> </tr>
<tr> <td> Vice Captain (Boy) </td> <td><b> Rohit Singh</b></td> </tr>
<tr> <td> Vice Captain (Girl) </td> <td><b> Ananya Sharma</b></td> </tr>
<tr> <td> Coordinator </td> <td><b>Gunjan Srivastava</b></td> </tr>
</tbody></table>
</div>
<div className="col-md-6">
 <h3>Nehru House</h3>
<table class="table" cellspacing="0" border="0">
<tbody><tr>
<th scope="col">Designantion</th><th scope="col">Name</th>
</tr> 
<tr> <td> Captain (Boy) </td> <td> <b>  Aryan Srivastava</b> </td> </tr>
<tr> <td> Captain (Girl) </td> <td><b> Roshni</b>  </td> </tr>
<tr> <td> Vice Captain (Boy) </td> <td> <b> Sayantan Banerjee</b> </td> </tr>
<tr> <td> Vice Captain (Girl) </td> <td> <b> Anshita Singh</b></td> </tr>
<tr> <td> Coordinator </td> <td> <b> Biraj Anant Ojha</b> </td></tr>
</tbody></table>
</div>
<div className="col-md-6">
 <h3>Tagore House</h3>
<table class="table" cellspacing="0" border="0">
<tbody><tr>
<th scope="col">Designantion</th><th scope="col">Name</th>
</tr> 
<tr> <td> Captain (Boy) </td> <td> <b>  Rudraansh Jaisawal</b> </td> </tr>
<tr> <td> Captain (Girl) </td> <td><b> Amishi</b>  </td> </tr>
<tr> <td> Vice Captain (Boy) </td> <td> <b> Abhinav Raj</b> </td> </tr>
<tr> <td> Vice Captain (Girl) </td> <td> <b> Arpita Mishra</b></td> </tr>
<tr> <td> Coordinator </td> <td> <b> Kriti Singh</b> </td></tr>
</tbody></table>
</div>
                </div>
            </div>
        </div>
     </>
  )
}

export default StudentCouncil

