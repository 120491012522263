import React, { useEffect } from 'react';

const Instagram = () => {
  useEffect(() => {
    // Load Instagram embed script
    const scriptId = 'instagram-embed-script';
    const scriptUrl = 'https://www.instagram.com/embed.js';

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = scriptUrl;
      script.async = true;
      script.onload = () => {
        // Process Instagram embeds once the script has loaded
         
      };
      document.body.appendChild(script);
    } else {
      // Reinitialize Instagram embeds if script already exists
      // Using setTimeout to ensure script is fully loaded
      setTimeout(() => {
        
      }, 1000); // Adjust the timeout if necessary
    }
  }, []);

  return (
    <div>
      {/* Embed Instagram Post */}
      <blockquote
        className="instagram-media"
        data-instgrm-permalink="https://www.instagram.com/hts.jsr/?igshid=YmM0MjE2YWMzOA%3D%3D" // Replace with your Instagram post URL
        data-instgrm-version="14"
        style={{ margin: '20px auto', minWidth: '100%',maxWidth: '100%', width: '100%' ,height:"350px"}}
      >
      </blockquote>
    </div>
  );
};

export default Instagram;
