import React  from 'react'; 
import { Link } from 'react-router-dom';
const Withdrawals = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Students</li>
                 <li>Withdrawals</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Withdrawals</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <ul className="list">
                     <li> When written application and the requisite fee is received from the parents, a Transfer Certificate will be issued. Before withdrawing a student from the school one month’s written notice or one month’s fee in lieu there of is required. </li>
                     <li>No Transfer Certificate will be issued until all dues have been cleared. </li>
                    <li> a parent behaves badly with any member of the school staff. </li>
                     </ul>
                     <h3>A pupil’s name will be struck off the rolls if:-</h3>
                        <ul className="list">
                        <li> The pupil fails two years in succession. </li>
                        <li> The pupil fails thrice in his/her school career. </li>
                        <li> The pupil’s conduct is unsatisfactory in spite of admonition. </li>
                        <li> A pupil receives two warning letters within a year or three warning letters in his/her school career. </li>
                        <li> The pupil is absent from school for an indefinite period without written permission from the school. </li>
                        <li> The pupil persists in irregular attendance. </li>
                        <li> fees remain unpaid for three consecutive months. </li>
                        <li> A parent behaves badly with any member of the school staff. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Withdrawals

