import React, { useState, useEffect } from "react";
import { academicCalender } from "../Service/Api";

function Calendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [monthEvents, setMonthEvents] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await academicCalender();
      setData(datas);
    };
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const monthEventsData = data
        .filter((event) => {
          const eventDate = new Date(event.date);
          return (
            eventDate.getMonth() === currentDate.getMonth() &&
            eventDate.getFullYear() === currentDate.getFullYear()
          );
        })
        .map((event) => ({
          day: new Date(event.date).getDate(), // Only the day number
          event: event.title,
        }))
        .sort((a, b) => a.day - b.day); // Sort events by day
      setMonthEvents(monthEventsData);
    }
  }, [data, currentDate]);

  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const renderCalendarDays = () => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const daysInMonth = Array.from({ length: lastDayOfMonth.getDate() }, (_, index) => index + 1);
    const startDay = firstDayOfMonth.getDay(); // 0 (Sun) to 6 (Sat)

    // Create an array for the days before the first day of the month
    const blankDays = Array.from({ length: startDay }, (_, index) => index);

    return (
      <>
        {blankDays.map((_, index) => (
          <div key={`blank-${index}`} className="col"></div>
        ))}
        {daysInMonth.map((day) => (
          <div
            key={day}
            className={`col ${monthEvents.find((event) => event.day === day) ? "active-day" : ""}`}
            onClick={() => handleDayClick(day)}
          >
            {day}
            {monthEvents.find((event) => event.day === day) && (
              <span className="day-event">•</span>
            )}
          </div>
        ))}
      </>
    );
  };

  const handleDayClick = (day) => {
    console.log("Clicked on day:", day);
  };

  // Correctly display today's date
  const todayDate = new Date();
  const todayDay = todayDate.toLocaleString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "short",
  });

  const monthName = currentDate.toLocaleString("en-US", { month: "long" });
  const yearNum = currentDate.getFullYear();

  return (
    <div className="event-cal">
      <div className="main-wrapper">
        <div className="sidebar-title">
          <h4>Today</h4>
          <h5 id="eventDayName">{todayDay}</h5>
        </div>
        <div className="content-wrapper grey lighten-3">
          <div className="calendar-wrapper z-depth-2">
            <div className='calender_block'>
              <div className='border-blk'>
                <div className="header-background">
              <div className="calendar-header">
                <button className="prev-button" onClick={() => changeMonth(-1)}>
                  <i className="bi bi-arrow-left-short"></i>
                </button>
                <button className="next-button" onClick={() => changeMonth(1)}>
                  <i className="bi bi-arrow-right-short"></i>
                </button>
                <div className="row header-title">
                  <div className="header-text">
                    <h3 id="month-name">{`${monthName} ${yearNum}`}</h3>
                    <h5 id="todayDayName">
                      Today is {todayDay}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="calendar-content">
              <div id="calendar-table" className="calendar-cells">
                <div id="table-header" className="row">
                  <div className="col">Sun</div>
                  <div className="col">Mon</div>
                  <div className="col">Tue</div>
                  <div className="col">Wed</div>
                  <div className="col">Thu</div>
                  <div className="col">Fri</div>
                  <div className="col red">Sat</div>
                </div>
                <div id="table-body" className="row">
                  {renderCalendarDays()}
                </div>
              </div>
            </div>
            </div>
        </div>
            <div className="calendar-footer">
              <div className="emptyForm eventlist" id="emptyForm">
                {monthEvents.length > 0 &&
                  monthEvents.map((event, index) => (
                    <p key={index}>
                      <span>{event.day}:</span> {event.event}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
