import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/HTSJ`);
    console.log(data)
    return data;
} 
 
export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/HTSJ`);
    console.log("news:",data)
    return data;
} 
export const getNewsInner = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/all/HTSJ`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/HTSJ`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/HTSJ`);
    console.log(data)
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/HTSJ`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/HTSJ`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/HTSJ`,getHeader());
    console.log("popup",data)
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/HTSJ`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/HTSJ`,getHeader());
    console.log("online registration Data", data)
    return data;
} 


export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/HTSJ`, getHeader());
    return data;
} 
 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/All/HTSJ`);
    return data;
} 
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/HTSJ`,{name,admission},getHeader());
    return data;
}
  
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/HTSJ/${type}`, formData, header);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };
  export const academicCalender = async() => {
    const { data } = await axios.get(API_URL + `/api/academicCalender/all/HTSJ`, getHeader());
    console.log("academic calender", data)
    return data;
}