import React  from 'react'; 
import { Link } from 'react-router-dom';
const Attendance = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Students</li>
                 <li>Attendance</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Attendance</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <ul className="list newlist">
                     <li> Every pupil must attend school regularly. Absence from school for reasons other than illness or serious unforeseen circumstances, will not be excused. </li>
                     <li> Leave may be sanctioned by the Principal on a written application from the parent if the reason is a serious one. Such an application must be made in advance by the parent, in person. </li>
                     <li> The gates of the school will be closed after the bell. Those who are late will not be allowed to attend school on that day. </li>
                     <li> A pupil who is absent from school or fails to attend school on account of being late will have to enter the absence in the ‘Absence Record’ and get it signed by the parent and countersigned by the Vice Principal </li>
                     <li> Pupils must never leave the school early without the written permission of the principal. </li>
                     <li> All applications for medical leave during weekly tests/exam days must be made to the Principal, by the parent. An authentic medical certificate from a registered practioner/hospital will be necessary. </li>
                     </ul>
 </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Attendance

