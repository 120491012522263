import React, { useState, useEffect } from 'react'; 
import {Link } from "react-router-dom";
import { getNewsInner } from "../Service/Api"; 

const News = () => {
    useEffect(() => {
        document.title = 'News - Hill Top School, Jamshedpur';  
    }, []);  
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setSelectedYear(currentYear.toString());
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getNewsInner();
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    return parseInt(selectedYear) === year;
                }
                return false;
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const month = new Date(item.fromDate).getMonth();
                    return monthNames[month] === selectedMonth;
                }
                return false;
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort the filteredData by month in descending order
        filteredData.sort((a, b) => {
            const dateA = new Date(a.fromDate);
            const dateB = new Date(b.fromDate);
            return dateB - dateA;
        });

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
    const emptyArray = [
        {title:"News Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"},
        {title:"News Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"}
       ];
    return (
        <>
        
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>  
             <li>News </li>
        </ul>
    </div> 
    <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>News </h1>
                </div>
            </div>
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                    {filterData.length > 0 ? (
                        filterData.map((item, index) => {
                            const date = new Date(item.fromDate);
                            const day = date.getDate();
                            const month = monthNames[date.getMonth()];
                            return (
                                <div className="col-lg-12 col-xl-6 newsCount" key={index}>
                                <div className="eventsnewsbox">
                                <div className="newsboximg">
                               <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image}`} className="img-fluid"   alt="HTS"/> 
                                </div>
                                  <div className="newsboxdesc"> 
                                     <div className="newspgh">
                                        
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                     </div>
                                     <div className="newsdate">
                                       <h2> {day} <span> {month} </span> </h2>
                                        <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                                     </div>
                                </div>
                         </div>
                                </div>
                                );
                            })
                          ) : (
                            emptyArray.map((item, index) => (
                                <div className="col-lg-12 col-xl-6 newsCount" key={index}>
                                <div className="eventsnewsbox">
                                <div className="newsboximg">
                                <img src="Images/news.jpg" className="img-fluid" alt="" /> 
                                </div>
                                  <div className="newsboxdesc"> 
                                     <div className="newspgh">
                                        
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                     </div>
                                     <div className="newsdate">
                                        <h2> {item.day} <span> {item.month} </span> </h2>
                                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                     </div>
                                </div>
                                </div>
                            </div>
                            ))
                       )}
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default News;
