import React, { useState } from "react"; 
import { Link } from 'react-router-dom'; 
import { form1 } from "../Service/Api";
import { getHeader } from "../config";

const Suggestion = () => { 
    const [formData, setFormData] = useState({
        name: "",  
        class: "",
        fatherName: "",
        date: "",
        message: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        // Clear the error for the current field
        setErrors({
            ...errors,
            [e.target.name]: ""
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.class) newErrors.class = "Class is required";
        if (!formData.fatherName) newErrors.fatherName = "Ward and class are required";
        if (!formData.date) newErrors.date = "Date is required";
        if (!formData.message) newErrors.message = "Suggestion is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop submission if validation fails
        }

        const type = 'Admission'; 
        console.log('Submitting form with type:', type); // Add this line for debugging
        try {
            const { header } = getHeader();
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            setFormData({
                name: "",  
                class: "",
                fatherName: "",
                date: "",
                message: "",
            }); // Reset form data
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link></li> 
                    <li> Contact Us</li>
                    <li>Suggestion</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Suggestion</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Do You Wish To Make A Suggestion In The Interest Of The School? If Yes Fill In the Form Given Below:</p>
                            <form onSubmit={handleSubmit} className="suggestionform">
                                <div className="formdiv">
                                    <div className="form-group">
                                        <label>Name<span>*</span></label>
                                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
                                        {errors.name && <div className="error">{errors.name}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>If you are a student, mention class:<span>*</span></label>
                                        <input type="text" name="class" className="form-control" value={formData.class} onChange={handleChange} />
                                        {errors.class && <div className="error">{errors.class}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>If you are a parent, mention your ward's name and class <span>*</span></label>
                                        <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange} />
                                        {errors.fatherName && <div className="error">{errors.fatherName}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>Date<span>*</span></label>
                                        <input type="text" name="date" placeholder="DD/MM/YY" className="form-control" value={formData.date} onChange={handleChange} />
                                        {errors.date && <div className="error">{errors.date}</div>}
                                    </div>
                                    <div className="form-group textarea">
                                        <label>Your Suggestion<span>*</span></label>
                                        <textarea name="message" className="form-control" value={formData.message} onChange={handleChange}></textarea>
                                        {errors.message && <div className="error">{errors.message}</div>}
                                    </div>
                                </div>
                                <input type="submit" value="Submit" />
                                <div className="row"> 
                                    <div className="col-lg-12 col-md-12">
                                        <div className='primary-alert'></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Suggestion;
