 
import React from 'react';

const YouTubeWidget = () => {

  return (
    <div className="youtube-widget">
      <iframe
        width="100%"
        height="350"
        src="https://www.youtube.com/embed/61-XnmtchiM?si=5GIxJJ4rJ__44r9P"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeWidget;
