// export const API_URL = "http://3.81.146.113" 
export const API_URL = "https://webapi.entab.info"; 


export const getHeader = () => {

    const token = localStorage.getItem('token');
    const schoolcode = localStorage.getItem('schoolcode');

    return {
        header: {
            'Authorization': `Bearer ${token}`
        },
        schoolcode,
    }
}