import React  from 'react'; 
import { Link } from 'react-router-dom';
const Admission = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>Students</li>
                 <li>Admission</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Admission</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                        <p>Thank you for your interest in pursuing your child’s admission to Hill Top School. The first step in our admission process begins with an application, mostly online. You may begin your online application by clicking on the link below. We have also included the time lines as per class. Admissions to a particular class depend on the number of seats available and the adherence to the deadlines.</p>
                    </div>
                </div>
                <div className="admissioninfo">

                             <div className="admissionbox"> <img src="Images/admission.png" className="img-fluid"/> <p>Nursery: Admission Notice 2023-2024 </p> <a href="https://d280nq1n4mqyso.cloudfront.net/api/image/HTSJ/public/pdf/Nursery_Form_2024-25.pdf" target="_blank">Click Here</a> </div>
                             <div className="admissionbox"> <img src="Images/admission.png" className="img-fluid"/> <p> Lateral(LKG to Class 9) : </p> <a href="#" target="_blank">Click Here</a> </div>
                             <div className="admissionbox"> <img src="Images/admission.png" className="img-fluid"/> <p>class-11: Admission Notice . </p> <a href="#" target="_blank">Click Here</a> </div>
                           
                         </div>
            </div>
        </div>
     </>
  )
}

export default Admission

